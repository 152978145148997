import React, { FC } from 'react';

import TopFooter from './TopFooter';
import BottomFooter from 'gatsby-theme-gaviscon/src/components/Footer/BottomFooter';
import MiddleFooter from 'gatsby-theme-gaviscon/src/components/Footer/MiddleFooter';

import './Footer.scss';

const Footer: FC<PageContent.IFooter> = ({
  logo,
  support,
  address,
  navigation,
  bottomLinks,
  copyright,
  middleCopyright,
  sponsors,
  image,
  lsSettings,
}) => {
  const hasBottomFooter = bottomLinks?.length || copyright;

  return (
    <div className="gs-footer">
      <TopFooter {...{ logo, support, address, navigation, lsSettings }} />
      <MiddleFooter {...{ middleCopyright, sponsors, image }} />
      {hasBottomFooter ? <BottomFooter links={bottomLinks} copyright={copyright} /> : null}
    </div>
  );
};

export default Footer;
