import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import useScreenRecognition from 'hooks/useScreenRecognition';
import { IGatsbyImageComponentProps } from './models';
import './GatsbyImage.scss';

const GatsbyImage: FC<IGatsbyImageComponentProps> = ({
  isLazyLoading = true,
  alt,
  className,
  objectFit,
  objectPosition,
  fluid,
  useFocalPoint,
  ...restProps
}) => {
  const padY = parseInt(fluid.focalPoint?.y || '50', 10);
  const padX = parseInt(fluid.focalPoint?.x || '50', 10);
  const svgRest: { [key: string]: any } = restProps;
  const { isNotMobile } = useScreenRecognition();

  const imageToRender = isNotMobile
    ? fluid?.gatsbyImage
    : fluid?.mobileImage
    ? fluid?.mobileImage
    : fluid?.gatsbyImage;

  const imageProps = {
    className,
    alt,
    objectFit: objectFit || 'cover',
    objectPosition: useFocalPoint ? `${padX}% ${padY}%` : objectPosition || '50% 50%',
    ...restProps,
  };

  const isGif = fluid.fallbackUrl?.includes('.gif');

  const loadingType = isLazyLoading ? 'lazy' : 'eager';

  return fluid.svg?.content ? (
    <DangerouslySetInnerHtml
      loading={loadingType}
      html={fluid.svg.content}
      element="div"
      className={`gatsby-svg-image ${className}`}
      alt={alt}
      {...svgRest}
    />
  ) : imageToRender?.childImageSharp?.fixed ? (
    <Img fixed={imageToRender?.childImageSharp?.fixed} loading={loadingType} {...imageProps} />
  ) : imageToRender?.childImageSharp?.fluid ? (
    <Img fluid={imageToRender?.childImageSharp?.fluid} loading={loadingType} {...imageProps} />
  ) : isGif ? (
    <img src={fluid.fallbackUrl} className={className} loading={loadingType} alt={alt} />
  ) : null;
};

export const query = graphql`
  fragment FragmentIcon on File {
    childImageSharp {
      fluid(maxHeight: 45) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  fragment FragmentMobileCard on File {
    childImageSharp {
      fluid(maxWidth: 210, maxHeight: 210, fit: CONTAIN, background: "transparent", quality: 70) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  fragment FragmentDesktopCard on File {
    childImageSharp {
      fluid(maxWidth: 260, maxHeight: 260, fit: CONTAIN, background: "transparent", quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  fragment FragmentTinyImage on File {
    childImageSharp {
      fluid(maxWidth: 260) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  fragment FragmentSmallImage on File {
    childImageSharp {
      fluid(maxWidth: 500, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  fragment FragmentMiddleImage on File {
    childImageSharp {
      fluid(maxWidth: 950, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  fragment FragmentLargeImage on File {
    childImageSharp {
      fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [475, 768, 950, 1280, 1440, 1900]) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  fragment FragmentGatsbyProps on TGatsbyImageProperties {
    fallbackUrl
    url
    focalPoint {
      type
      x
      y
    }
    svg {
      content
    }
    altText
  }

  fragment FragmentGatsbyIcon on TGatsbyImageProperties {
    ...FragmentGatsbyProps
    gatsbyImage {
      ...FragmentIcon
    }
  }

  fragment FragmentGatsbyImage on TGatsbyImageProperties {
    ...FragmentGatsbyProps
    gatsbyImage {
      ...FragmentLargeImage
    }
  }
  
  fragment FragmentGatsbyImageTiny on TGatsbyImageProperties {
    ...FragmentGatsbyProps
    gatsbyImage {
      ...FragmentTinyImage
    }
  }

  fragment FragmentGatsbyImageSm on TGatsbyImageProperties {
    ...FragmentGatsbyProps
    gatsbyImage {
      ...FragmentSmallImage
    }
  }

  fragment FragmentGatsbyImageMd on TGatsbyImageProperties {
    ...FragmentGatsbyProps
    gatsbyImage {
      ...FragmentMiddleImage
    }
  }

  fragment FragmentGatsbyImageLg on TGatsbyImageProperties {
    ...FragmentGatsbyProps
    gatsbyImage {
      ...FragmentLargeImage
    }
  }

  fragment FragmentGatsbyCardImage on TGatsbyImageProperties {
    ...FragmentGatsbyProps
    gatsbyImage {
      ...FragmentDesktopCard
    }
    mobileImage {
      ...FragmentMobileCard
    }
  }
`;

export default GatsbyImage;
