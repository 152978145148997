import React, { FC, useCallback } from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import DangerouslySetInnerHtml from 'gatsby-theme-gaviscon/src/components/common/DangerouslySetInnerHtml';
import { IBurgerLinks } from '../model';
import { isBrowser } from 'gatsby-theme-gaviscon/src/utils/browser';
import './MobileMenuAccordionLink.scss';

const MobileMenuAccordionLink: FC<IBurgerLinks> = ({ links }) => {
  const location = useLocation();
  const needReloadCheck = useCallback(
    (url) => () => {
      isBrowser() && location.pathname === url && window.location.reload();
    },
    []
  );

  return (
    <ul className="gs-burger-navigation__links gs-burger-navigation__links-accordion">
      {links.map(({ properties: { link, label } }) => (
        <li key={label} className="gs-burger-navigation__links-item gs-burger-navigation__links-accordion-item">
          <Link
            onClick={needReloadCheck(link?.[0]?.url)}
            to={link?.[0]?.url}
            partiallyActive
            activeClassName="active"
          >
            <DangerouslySetInnerHtml element="span" html={label} />
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default MobileMenuAccordionLink;
