import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'gatsby';

import GatsbyImage from 'gatsby-theme-gaviscon/src/components/common/GatsbyImage';
import IconCustom from 'gatsby-theme-gaviscon/src/components/common/IconCustom';
import { parseBoolean } from 'gatsby-theme-gaviscon/src/utils/parseHelpers';
import LanguageSelector from 'gatsby-theme-gaviscon/src/components/common/LanguageSelector';
import DangerouslySetInnerHtml from 'gatsby-theme-gaviscon/src/components/common/DangerouslySetInnerHtml';
import Support from './components/Support';
import Address from './components/Address';

import './TopFooter.scss';
import { ITopFooter } from './model';

const TopFooter: FC<ITopFooter> = ({ logo, navigation, address, support, lsSettings }) => (
  <div className="gs-footer-top">
    <Container fluid>
      {logo?.length ? (
        <GatsbyImage
          className="gs-footer-top__logo"
          fluid={logo[0].properties.logo}
          alt={logo[0].properties.altText}
        />
      ) : null}
      <Row>
        {support?.length && address?.length ? (
          <Col lg="3">
            {parseBoolean(lsSettings.inFooter) ? (
              <LanguageSelector type="list" {...lsSettings} />
            ) : null}
            <Support {...{ support }} />
            <Address {...{ address }} />
          </Col>
        ) : null}
        <Col lg="9" className="gs-footer-top__nav">
          {
            navigation?.map(({ properties: { title, links, titleLink } }) => (
              <div className="gs-footer-top__nav-group" key={title}>
                {titleLink[0]?.url ? (
                  <p className="gs-footer-top__title">
                    <Link to={titleLink[0].url} className="gs-footer-top__title--link">
                      {title}
                    </Link>
                  </p>
                ) : (
                  <DangerouslySetInnerHtml
                    className="gs-footer-top__title"
                    element="p"
                    html={title}
                  />
                )}

                {links?.length ? (
                  <ul className="gs-footer-top__nav-list">
                    {links.map(({ properties: { link: [{ name, url }] } }) => (
                      <li key={name}>
                        <Link to={url}>
                          <span className="gs-footer-top__nav-list-label">{name}</span>
                          <IconCustom icon="chevron-right" />
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </div>
            ))}
        </Col>
      </Row>
    </Container>
  </div>
);

export default TopFooter;
