import React, { FC, useState, useEffect } from 'react';

import IconCustom from 'gatsby-theme-gaviscon/src/components/common/IconCustom';
import Button from 'gatsby-theme-gaviscon/src/components/common/Button';
import DangerouslySetInnerHtml from 'gatsby-theme-gaviscon/src/components/common/DangerouslySetInnerHtml';
import LanguageSelector from 'gatsby-theme-gaviscon/src/components/common/LanguageSelector';
import { parseBoolean } from 'gatsby-theme-gaviscon/src/utils/parseHelpers';
import Links from './Links';

import { IBurgerNavigation } from './model';
import './BurgerNavigation.scss';
import MobileMenuAccordionLink from './MobileMenuAccordionLink';

const BurgerNavigation: FC<IBurgerNavigation> = ({
  navigation,
  ariaBurger,
  lsSettings,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 1220px)');

    const screenWidthHandler = () => {
      if (mediaQuery.matches) {
        setIsOpen(false);
      }
    };

    mediaQuery.addEventListener('change', screenWidthHandler);

    return () => {
      mediaQuery.removeEventListener('change', screenWidthHandler);
    };
  }, []);

  useEffect(() => {
    const bodyElement = document.querySelector('body');

    if (isOpen) {
      bodyElement?.classList.add('no-scroll');
    } else {
      bodyElement?.classList.remove('no-scroll');
    }

    return () => {
      bodyElement?.classList.remove('no-scroll');
    };
  }, [isOpen]);

  return (
    <nav className="gs-burger-navigation">
      <Button
        variant="icon"
        clickHandler={handleClick}
        classes="gs-burger-navigation__button"
        ariaLabel={ariaBurger}
      >
        <IconCustom icon={isOpen ? 'close' : 'menu'} />
      </Button>
      {isOpen ? (
        <div className="gs-burger-navigation__panel">
          {navigation.map(({ properties: { sectionLabel, links } }) => (
            (sectionLabel !== 'Products') ? (
              <div key={sectionLabel} className="gs-burger-navigation__section">
                <DangerouslySetInnerHtml
                  element="span"
                  className="gs-burger-navigation__title"
                  html={sectionLabel}
                />
                {links.length ? <Links {...{ links }} /> : null}
              </div>
            ) : 
            <div key={sectionLabel} onClick={() => setIsActive(!isActive)} className={`gs-burger-navigation__section gs-burger-navigation__section-accordion ${isActive ? 'accordion-expanded' : ''}`}>
              <DangerouslySetInnerHtml
                element="span"
                className="gs-burger-navigation__title"
                html={`${sectionLabel} <span aria-hidden="true" class="gs-icon-custom icon-chevron-right"></span>`}
              />
              {links.length ? <MobileMenuAccordionLink {...{ links }} /> : null}
            </div>
          ))}
          {parseBoolean(lsSettings.inBurger) ? (
            <LanguageSelector type="list" {...lsSettings} />
          ) : null}
        </div>
      ) : null}
    </nav>
  );
};

export default BurgerNavigation;
