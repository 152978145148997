import React, { FC } from 'react';
import DangerouslySetInnerHtml from 'gatsby-theme-gaviscon/src/components/common/DangerouslySetInnerHtml';
import { SupportProps } from '../model';

const Support: FC<SupportProps> = ({
  support: [
    {
      properties: { title, phone },
    },
  ],
}) => (
  <div className="gs-footer-top__support">
    <DangerouslySetInnerHtml className="gs-footer-top__title" element="p" html={title} />
    {phone?.map((item) => (
      <div className="gs-footer-top__phone" key={item}>
        <a href={`tel:${item.replace(/\s+/g, '')}`}>{item}</a>
      </div>
    ))}
  </div>
);

export default Support;
